module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Monogram House - Screen Printing and Embroidery","short_name":"Monogram House","start_url":"/","background_color":"#000","theme_color":"#000","display":"standalone","icon":"src/images/mono-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"524c54c442d8c695bf92194af8a86d6d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"crumbLabelUpdates":[{"pathname":"/about-us","crumbLabel":"About us"},{"pathname":"/services","crumbLabel":"Services"},{"pathname":"/services/embroidery","crumbLabel":"Embroidery"},{"pathname":"/services/screen-printing","crumbLabel":"Screen Printing"},{"pathname":"/services/team-wear","crumbLabel":"Team Wear"},{"pathname":"/services/clothing-brands","crumbLabel":"Clothing brands"},{"pathname":"/contact-us","crumbLabel":"Contact us"},{"pathname":"/get-a-quote","crumbLabel":"Get a quote"}],"exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
