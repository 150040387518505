// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-clothing-brands-js": () => import("./../../../src/pages/services/clothing-brands.js" /* webpackChunkName: "component---src-pages-services-clothing-brands-js" */),
  "component---src-pages-services-embroidery-js": () => import("./../../../src/pages/services/embroidery.js" /* webpackChunkName: "component---src-pages-services-embroidery-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-screen-printing-js": () => import("./../../../src/pages/services/screen-printing.js" /* webpackChunkName: "component---src-pages-services-screen-printing-js" */),
  "component---src-pages-services-team-wear-js": () => import("./../../../src/pages/services/team-wear.js" /* webpackChunkName: "component---src-pages-services-team-wear-js" */)
}

